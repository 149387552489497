import 'react-quill/dist/quill.snow.css';

import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import ReactQuill from "react-quill";

import * as Types from '../types';

import classes from '../Field.module.scss';

interface IProps extends Types.FieldProps { }

const TextEditor: React.FC<IProps> = ({
  label,
  required,
  placeholder,
  maxLength = 4000,
  showError = true,
  disabled,
  readOnly,
  prefix,
  ...props }) => {
  const [field, meta, helpers] = useField(props);

  const [qLength, setLength] = useState(0)

  const hasError = !!(showError && meta.touched && meta.error);

  useEffect(() => {
    setLength(field.value?.length)
  }, [field.value])

  const toolbarId = "toolbar";

  return (
    <div className={
      cx(classes.wrapper,
        disabled && classes['wrapper--disabled'],
        readOnly && classes['wrapper--readonly'],
        hasError && classes['wrapper--error'],
        classes[`wrapper--type-textEditor`])}>
      {!!label &&
        <div className={classes.label} title={label}>
          {!!required && <span>*</span>}{label}
        </div>}
      <div className={classes.wrapperInner}>
        <CustomToolbar
          id={toolbarId}
          disabled={disabled || readOnly} />
        <ReactQuill
          {...{ placeholder, disabled, readOnly }}
          id={field.name}
          className={cx(classes.input, classes.textEditor)}
          theme="snow"
          value={field.value || ''}
          onChange={(text) => helpers.setValue(text)}
          modules={{
            toolbar: {
              container: "#" + toolbarId
            }
          }}
        />
      </div>
      <div>{qLength}/{maxLength}</div>
      {!!hasError && (
        <div className={classes.errorLabel}>{meta.error}</div>
      )}
    </div>
  );
};

const CustomToolbar = ({ id, disabled }) => (
  <div id={id} className={classes.textEditorToolbar} style={disabled ? { display: 'none' } : {}}>
    <select className="ql-size" />
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <button className="ql-blockquote" />
    <select className="ql-color" defaultValue="#000000">
      {[
        '#000000',
        '#e60000',
        '#ff9900',
        '#ffff00',
        '#008a00',
        '#0066cc',
        '#9933ff',
        '#ffffff',
        '#facccc',
        '#ffebcc',
        '#ffffcc',
        '#cce8cc',
        '#cce0f5',
        '#ebd6ff',
        '#bbbbbb',
        '#f06666',
        '#ffc266',
        '#ffff66',
        '#66b966',
        '#66a3e0',
        '#c285ff',
        '#888888',
        '#a10000',
        '#b26b00',
        '#b2b200',
        '#006100',
        '#0047b2',
        '#6b24b2',
        '#444444',
        '#5c0000',
        '#663d00',
        '#666600',
        '#003700',
        '#002966',
        '#3d1466'
      ].map((item, idx) => (
        <option key={idx} value={item} />
      ))}
    </select>
    <select className="ql-background" />
    <span className="ql-formats">
      <button className="ql-align" value="" />
      <button className="ql-align" value="center" />
      <button className="ql-align" value="right" />
      <button className="ql-align" value="justify" />
    </span>
    <button className="ql-script" value="sub" />
    <button className="ql-script" value="super" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <button className="ql-indent" value="-1" />
    <button className="ql-indent" value="+1" />
    <button className="ql-image" />
    <button className="ql-clean" />
  </div>
);

export default React.memo(TextEditor);
